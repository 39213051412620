<template>
  <div>
    <dan-message
      v-for="item in getMessages(domainName)"
      :key="item.id"
      :message="item"
    />
  </div>
</template>

<script>
import { mapGetters} from "vuex";
import DanMessage from "./DanMessage.vue";
export default {
  components: {DanMessage},
  props: ["domainName"],
  created() {
    this.$store.dispatch(
      "inventory/loadDanMessagesByDomainNameAction",
      this.domainName
    );
  },
  computed: {
    ...mapGetters({
      getMessages: "inventory/getDanMessagesByDomainName",
    }),
    headers() {
      return [{ text: "#", value: "id" }];
    },
  },
};
</script>

<style></style>
