<template>
  <v-row>
    <v-col>
      Sent on:<date-display
        :date="message.sentDate"
        date-format="MMM DD HH:mm:ss"
      />
    </v-col>
    <v-col>
      {{ message.contactName }}
    </v-col>
    <v-col> {{ message.email }} </v-col>
    <v-col> {{ message.phone }} </v-col>
    <v-col> ${{ message.price }} </v-col>
    <v-col>
      {{ message.lead ? "Lead" : "Transaction" }}
    </v-col>
    <v-col> 
      <v-checkbox
        v-model="cancelled"
        label="Cancelled"
        class="pa=0"
        @click="updateDanMessage"
      />
    </v-col>
    <v-col>
      <resend-with-dan-menu-small :message="message" />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DanMessage",
  components: {
    DateDisplay: () => import("@/components/DateDisplay"),
    ResendWithDanMenuSmall: () => import("@/components/ResendWithDanMenuSmall"),
  },
  props: ["message"],
  data: () => ({
    menu: false,
    cancelled: false,
  }),
  created(){
    this.cancelled=this.message.cancelled;
  },
  methods: {
    ...mapActions("inventory", ["updateDanMessageAction"]),
    updateDanMessage(){
      let newMessage = {};
      Object.assign(newMessage, this.message);
      newMessage.cancelled=this.cancelled;
      this.updateDanMessageAction(newMessage);
    }
  },
};
</script>

<style lang="scss" scoped></style>
