<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        fab
        x-small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-email</v-icon>
      </v-btn>
    </template>
    <div v-if="loading">
      LOADING
    </div>
    <v-card v-else>
      <v-card-text>
        <v-select
          v-model="sibling"
          :items="siblings"
          label="Sibling*"
          required
        />

        <div>
          <v-text-field
            v-if="contactName || !names || names.length === 0"
            v-model="contactName"
            label="Full Name/Company*"
            required
          />

          <v-select
            v-else
            v-model="contactName"
            :items="names"
            label="Full Name/Company"
          />
        </div>

        <div>
          <v-text-field
            v-if="phone || !phones || phones.length === 0"
            v-model="phone"
            label="Phone*"
            required
          />
          <v-select
            v-else
            v-model="phone"
            :items="phones"
            label="Phone*"
          />
        </div>

        <div>
          <v-text-field
            v-if="email || !emails || emails.length === 0"
            v-model="email"
            label="Email*"
            required
          />
          <v-select
            v-else
            v-model="email"
            :items="emails"
            label="Email"
          />
        </div>

        <v-text-field
          v-model="price"
          :rules="priceRules"
          label="Price*"
          required
        />

        <v-autocomplete
          v-model="type"
          :items="['Buy Now', 'Make Offer', 'Lease To Own']"
          label="Type"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          @click="sendWithDan"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import tools from "@/util/tools.js";

export default {
  props: ["danDomain"],
  data: () => ({
    id: 0,
    menu: false,
    type: "",
    sibling: "",
    contactName: "",
    phone: "",
    email: "",
    price: "",
    priceRules: [
      (value) => !!value || "Required.",
      (value) => {
        const pattern = /^[0-9]+$/;
        return pattern.test(value) || "Invalid number.";
      },
    ],
  }),

  computed: {
    ...mapGetters("domains", ["getDomainByName"]),

    ...mapGetters({
      conversations: "conversations/getConversationsByDomainName",
    }),

    domain(){
      return this.getDomainByName(this.danDomain.name);
    },
    siblings() {
      if (this.domain) {
        let result = this.domain.siblings
          ? this.domain.siblings.map((sibling) => sibling.name)
          : [];
        return result;
      } else return [];
    },
    names() {
      if (this.domain) {
        const sib = this.domain.siblings.find((s) => s.name === this.sibling);
        let result = [];
        if (sib) {
          result = [sib.name.substr(0, sib.name.indexOf(".")).toLowerCase()];

          if (sib.scanResult && sib.scanResult.domainRegistration) {
            let names = sib.scanResult.domainRegistration.contacts.map(
              (c) => c.name
            );
            let company = sib.scanResult.domainRegistration.contacts.map(
              (c) => c.organization
            );
            result = [...names, ...company, ...result].filter((n) => n);
          }
        }

        return result;
      } else return ["no domain?"];
    },
    phones() {
      if (this.domain) {
        let chats = this.conversations(this.domain.name);
        let conversationsPhones = chats.map(c=>c.phone);

        const sib = this.domain.siblings.find((s) => s.name === this.sibling);
        let result = [...conversationsPhones];
        if (sib && sib.scanResult) {
          if (sib.scanResult.whoisRecord) {

            let contactsPhones = sib.scanResults.map(sr => tools.fetchPhones(sr));
            result = [...contactsPhones.flat(1), ...result];
          }

          if (sib.scanResult.crawlReport) {
            let crawledPhones = sib.scanResult.crawlReport
              ? sib.scanResult.crawlReport.phones
              : [];
            result = [...crawledPhones, ...result];
          }
        }
        result = result.filter((n) => n);
        return result;
      } else return [];
    },
    emails() {
      if (this.domain && this.sibling) {
        const sib = this.domain.siblings.find((s) => s.name === this.sibling);
        let result = [];
        let scannedEmails = sib.scanResults.map(sr => tools.fetchEmails(sr));
        let chats = this.conversations(this.domain.name);
        let messages = chats.map((c) => c.messages).flat();
        let texts = messages.map((m) => JSON.stringify(m.text));
        let matches = texts.map((t) =>
          t
            .split(" ")
            .map((w) =>
              w.match(
                /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi
              )
            )
            .flat()
            .filter((s) => s !== null)
        );

        result = [
          ...scannedEmails.flat(1),
          ...matches.flat(1),
        ].filter((n) => n);

        console.log("Scanned and found emails ", result);
        return result;
      }
      else return [];
    },
    loading: {
      get() {
        return this.$store.state.domains.loading;
      },
      set(val) {
        this.$store.commit("domains/SET_LOADING", val);
      },
    },
  },
  methods: {
    ...mapActions("inventory", ["sendWithDanAction"]),
    ...mapActions("domains", ["loadDomainByNameAction"]),
    sendWithDan() {
      let message = {
        domain: this.domain.name,
        contactName: this.contactName,
        phone: this.phone,
        email: this.email,
        price: this.price,
        // lead: this.lead === "Lead" ? true : false,
        type: this.type,
      };
      this.sendWithDanAction(message);
      this.menu = false;
    },
  },

  async mounted(){
    if (!this.domain) {
      await this.$store.dispatch(
        "domains/loadDomainByNameAction",
        this.danDomain.name
      );
    }
    this.price=this.danDomain.price;
  }

};
</script>

<style></style>
